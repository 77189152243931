import * as React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const RichiestaInviata = () => {
    return (
        <Layout>
            <SEO title="Richiesta inviata con successo" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-center justify-between gap-8">
                        <div className="w-full lg:w-5/12 flex items-center">
                            <StaticImage src="../images/single01.png" alt="Demo Gratuita Thelgo" placeholder="blurred" className="mx-auto w-1/3 lg:w-full" />
                        </div>
                        <div className="flex-1 flex flex-col items-center">
                            <h1 className="text-4xl lg:text-5xl text-center">Richiesta inviata con successo!</h1>
                            <span className="block text-lg lg:text-xl text-gray-500 text-center mt-4">Grazie per la tua richiesta. Il nostro staff ti contattarà nel più breve tempo possibile.</span>
                            <Link to="/" className="button-lg bg-persian-green-500 inline-flex mt-4 text-white hover:bg-persian-green-700 transition-colors">Torna alla home</Link>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default RichiestaInviata